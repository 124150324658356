<page-header icon="Measurements" pageTitle="Water Measurements" [customRichTextTypeID]="richTextTypeID"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <div class="transaction-options grid-12">
        <div class="g-col-12" *ngIf="geography?.DisplayUsageGeometriesAsField">
            <h2 class="section-title">Upload Usage Entity GDB</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p>Upload a valid GDB containing usage entities.</p>
            </div>

            <fresca-button [routerLink]="['upload-usage-entity-gdb']" cssClass="btn btn-primary" iconClass="" [disabled]="isDownloading">
                <span *ngIf="isDownloading" class="fa fa-spinner loading-spinner"></span>
                Upload Usage Entity GDB
            </fresca-button>
        </div>

        <div class="g-col-6 border-right" *ngIf="canCreateTransactions()">
            <h2 class="section-title">Upload Water Measurements (CSV)</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p>Upload water measurements for multiple parcels by uploading a comma-separated spreadsheet containing water measurements per Parcel.</p>
            </div>

            <fresca-button [routerLink]="['upload-water-measurements']" cssClass="btn btn-primary" iconClass=""> Upload Water Measurement Data (CSV) </fresca-button>
        </div>

        <div class="g-col-6" *ngIf="canCreateTransactions()">
            <h2 class="section-title">Upload Raster (TIF)</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p>Upload a valid raster file to create water measurements.</p>
            </div>

            <fresca-button [routerLink]="['raster-upload']" cssClass="btn btn-primary" iconClass=""> Upload Raster (TIF) </fresca-button>
        </div>

        <div class="g-col-6 border-right">
            <h2 class="section-title">OpenET Integration</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p></p>
            </div>

            <fresca-button [routerLink]="['openet-integration']" cssClass="btn btn-primary" iconClass=""> OpenET Integration </fresca-button>
        </div>

        <div class="g-col-6">
            <h2 class="section-title">Refresh Water Measurement Calculations</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p>Refresh all water measurement calculations for a specified year.</p>
            </div>

            <fresca-button (click)="refreshWaterMeasurementCalculations()" cssClass="btn btn-primary" iconClass="" [disabled]="isDownloading">
                <span *ngIf="isDownloading" class="fa fa-spinner loading-spinner"></span>
                Refresh Water Measurement Calculations
            </fresca-button>
        </div>

        <div class="g-col-6 border-right download">
            <h2 class="section-title">Download Water Measurement Data</h2>
            <div class="copy copy-3 pt-1 pb-2">
                <p>Download all water measurements from the platform for all configured water measurement data sources.</p>
            </div>

            <fresca-button (click)="downloadWaterMeasurements()" cssClass="btn btn-primary" iconClass="" [disabled]="isDownloading">
                <span *ngIf="isDownloading" class="fa fa-spinner loading-spinner"></span>
                Download Water Measurement Data
            </fresca-button>
        </div>
    </div>
</div>
