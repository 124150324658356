<div class="nav-container">
    <div class="dashboard-navbar">
        <a routerLink="/water-dashboard/water-accounts" class="tab" routerLinkActive="active">
            <icon icon="WaterAccounts"></icon>
            Water Accounts
        </a>
        <a routerLink="/water-dashboard/parcels" class="tab" routerLinkActive="active">
            <icon icon="Parcels"></icon>
            Parcels
        </a>
        <a routerLink="/water-dashboard/wells" class="tab" routerLinkActive="active">
            <icon icon="Wells"></icon>
            Wells
        </a>

        <ul class="manager-tools-nav">
            <li class="nav-item dropdown" routerLinkActive="active" *ngIf="user$ | async">
                <a
                    *ngIf="canViewManageTools"
                    href="javascript:void(0);"
                    [dropdownToggle]="managerToolsDropdown"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <icon icon="Manage"></icon>
                    <span class="nav-link__label">
                        Manager Tools
                        <icon icon="AngleDown" class="small"></icon>
                    </span>
                </a>

                <div #managerToolsDropdown class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div class="dropdown-section-header">Water Accounts</div>
                    <a routerLink="/water-accounts/{{ getGeographySlug() }}/suggestions" class="dropdown-item">Water Account Suggestions</a>
                    <a class="dropdown-item" (click)="createWaterAccountModal()">Create New Water Account</a>

                    <div class="dropdown-divider"></div>
                    <div class="dropdown-section-header">Parcels</div>
                    <a routerLink="/parcels/{{ getGeographySlug() }}/update" class="dropdown-item">Update Parcels</a>
                    <a routerLink="/parcels/{{ getGeographySlug() }}/zones/upload" class="dropdown-item">Assign Parcels to Zones</a>
                    <a routerLink="/parcels/{{ getGeographySlug() }}/bulk-actions" class="dropdown-item">Bulk Actions</a>

                    <div class="dropdown-divider"></div>
                    <div class="dropdown-section-header">Wells</div>
                    <a routerLink="/wells/{{ getGeographySlug() }}/review-submitted-wells" class="dropdown-item" *ngIf="geography?.WellRegistryEnabled">Review Submitted Wells</a>
                    <a routerLink="/wells/{{ getGeographySlug() }}/well-registrations" class="dropdown-item" *ngIf="geography?.WellRegistryEnabled">Well Registrations</a>
                    <a routerLink="/wells/{{ getGeographySlug() }}/reference-wells" class="dropdown-item" *ngIf="geography?.WellRegistryEnabled">Reference Wells</a>
                    <a routerLink="/wells/{{ getGeographySlug() }}/bulk-upload" class="dropdown-item">Bulk Upload Wells</a>
                    <a *ngIf="geography?.MetersEnabled" routerLink="/wells/{{ getGeographySlug() }}/meters" class="dropdown-item">Meters</a>
                </div>
            </li>
        </ul>
    </div>
</div>
