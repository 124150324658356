<div class="modal-header">
    <h3 class="section-title">Create a Frequently Asked Question</h3>
    <button type="button" class="close" (click)="close()">
        <i class="fa fa-times-circle"></i>
    </button>
</div>

<div class="modal-body">
    <form action="" class="form" [formGroup]="formGroup">
        <form-field class="m-2" [formControl]="formGroup.controls.QuestionText" fieldLabel="Question" placeholder="Question" [type]="FormFieldType.Textarea"></form-field>

        <form-field class="m-2" [formControl]="formGroup.controls.AnswerText" fieldLabel="Answer" placeholder="Answer" [type]="FormFieldType.RTE"></form-field>

        <ngx-select-dropdown
            *ngIf="faqDisplayLocationTypes$ | async as faqDisplayLocationTypes"
            [multiple]="true"
            [config]="dropdownConfig"
            [formControl]="dropdownFormControl"
            (change)="changedDisplayLocations($event)"
            [options]="faqDisplayLocationTypesFormInputOptions"></ngx-select-dropdown>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()" [disabled]="formGroup.invalid">Create</button>
    <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
</div>
