/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BoundingBoxDto } from '../model/bounding-box-dto';
import { ZoneDetailedDto } from '../model/zone-detailed-dto';
import { ZoneGroupMinimalDto } from '../model/zone-group-minimal-dto';
import { ZoneGroupMonthlyUsageDto } from '../model/zone-group-monthly-usage-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ZoneGroupService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupZoneGroupIDClearDelete(geographyID: number, zoneGroupID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDZoneGroupZoneGroupIDClearDelete(geographyID: number, zoneGroupID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDClearDelete(geographyID: number, zoneGroupID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDClearDelete(geographyID: number, zoneGroupID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDClearDelete.');
        }

        if (zoneGroupID === null || zoneGroupID === undefined) {
            throw new Error('Required parameter zoneGroupID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDClearDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-group/${encodeURIComponent(String(zoneGroupID))}/clear`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupID 
     * @param uploadedFile 
     * @param aPNColumnName 
     * @param zoneColumnName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupZoneGroupIDCsvPost(geographyID: number, zoneGroupID: number, uploadedFile: Blob, aPNColumnName: string, zoneColumnName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDZoneGroupZoneGroupIDCsvPost(geographyID: number, zoneGroupID: number, uploadedFile: Blob, aPNColumnName: string, zoneColumnName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDCsvPost(geographyID: number, zoneGroupID: number, uploadedFile: Blob, aPNColumnName: string, zoneColumnName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDCsvPost(geographyID: number, zoneGroupID: number, uploadedFile: Blob, aPNColumnName: string, zoneColumnName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDCsvPost.');
        }

        if (zoneGroupID === null || zoneGroupID === undefined) {
            throw new Error('Required parameter zoneGroupID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDCsvPost.');
        }

        if (uploadedFile === null || uploadedFile === undefined) {
            throw new Error('Required parameter uploadedFile was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDCsvPost.');
        }

        if (aPNColumnName === null || aPNColumnName === undefined) {
            throw new Error('Required parameter aPNColumnName was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDCsvPost.');
        }

        if (zoneColumnName === null || zoneColumnName === undefined) {
            throw new Error('Required parameter zoneColumnName was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDCsvPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (uploadedFile !== undefined) {
            formParams = formParams.append('UploadedFile', <any>uploadedFile) || formParams;
        }
        if (aPNColumnName !== undefined) {
            formParams = formParams.append('APNColumnName', <any>aPNColumnName) || formParams;
        }
        if (zoneColumnName !== undefined) {
            formParams = formParams.append('ZoneColumnName', <any>zoneColumnName) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-group/${encodeURIComponent(String(zoneGroupID))}/csv`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupZoneGroupIDDelete(geographyID: number, zoneGroupID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ZoneGroupMinimalDto>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDDelete(geographyID: number, zoneGroupID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDDelete(geographyID: number, zoneGroupID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupZoneGroupIDDelete(geographyID: number, zoneGroupID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDDelete.');
        }

        if (zoneGroupID === null || zoneGroupID === undefined) {
            throw new Error('Required parameter zoneGroupID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Array<ZoneGroupMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-group/${encodeURIComponent(String(zoneGroupID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupSlug 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet(geographyID: number, zoneGroupSlug: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ZoneGroupMonthlyUsageDto>>;
    public geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet(geographyID: number, zoneGroupSlug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZoneGroupMonthlyUsageDto>>>;
    public geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet(geographyID: number, zoneGroupSlug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZoneGroupMonthlyUsageDto>>>;
    public geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet(geographyID: number, zoneGroupSlug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet.');
        }

        if (zoneGroupSlug === null || zoneGroupSlug === undefined) {
            throw new Error('Required parameter zoneGroupSlug was null or undefined when calling geographiesGeographyIDZoneGroupZoneGroupSlugWaterUsageGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ZoneGroupMonthlyUsageDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-group/${encodeURIComponent(String(zoneGroupSlug))}/water-usage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ZoneGroupMinimalDto>>;
    public geographiesGeographyIDZoneGroupsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ZoneGroupMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupsPost(geographyID: number, zoneGroupMinimalDto?: ZoneGroupMinimalDto, observe?: 'body', reportProgress?: boolean): Observable<Array<ZoneGroupMinimalDto>>;
    public geographiesGeographyIDZoneGroupsPost(geographyID: number, zoneGroupMinimalDto?: ZoneGroupMinimalDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupsPost(geographyID: number, zoneGroupMinimalDto?: ZoneGroupMinimalDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZoneGroupMinimalDto>>>;
    public geographiesGeographyIDZoneGroupsPost(geographyID: number, zoneGroupMinimalDto?: ZoneGroupMinimalDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ZoneGroupMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-groups`,
            zoneGroupMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupsSortOrderPut(geographyID: number, zoneGroupMinimalDto?: Array<ZoneGroupMinimalDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDZoneGroupsSortOrderPut(geographyID: number, zoneGroupMinimalDto?: Array<ZoneGroupMinimalDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDZoneGroupsSortOrderPut(geographyID: number, zoneGroupMinimalDto?: Array<ZoneGroupMinimalDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDZoneGroupsSortOrderPut(geographyID: number, zoneGroupMinimalDto?: Array<ZoneGroupMinimalDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupsSortOrderPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-groups/sort-order`,
            zoneGroupMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupSlug 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZoneGroupsZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public geographiesGeographyIDZoneGroupsZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public geographiesGeographyIDZoneGroupsZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public geographiesGeographyIDZoneGroupsZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZoneGroupsZoneGroupSlugGet.');
        }

        if (zoneGroupSlug === null || zoneGroupSlug === undefined) {
            throw new Error('Required parameter zoneGroupSlug was null or undefined when calling geographiesGeographyIDZoneGroupsZoneGroupSlugGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zone-groups/${encodeURIComponent(String(zoneGroupSlug))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDZonesGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ZoneDetailedDto>>;
    public geographiesGeographyIDZonesGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZoneDetailedDto>>>;
    public geographiesGeographyIDZonesGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZoneDetailedDto>>>;
    public geographiesGeographyIDZonesGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDZonesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ZoneDetailedDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/zones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneGroupBoundingBoxPost(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public zoneGroupBoundingBoxPost(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public zoneGroupBoundingBoxPost(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public zoneGroupBoundingBoxPost(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BoundingBoxDto>(`${this.basePath}/zone-group/bounding-box`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
