import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { IconComponent } from "src/app/shared/components/icon/icon.component";
import { DropdownToggleDirective } from "../../directives/dropdown-toggle.directive";
import { DropdownToggleCloseDirective } from "../../directives/dropdown-toggle-close.directive";
import { ModalService, ModalSizeEnum, ModalThemeEnum } from "../../services/modal/modal.service";
import { CreateWaterAccountComponent } from "../water-account/modals/create-water-account/create-water-account.component";
import { AlertService } from "../../services/alert.service";
import { WaterDashboardGeographyDto } from "../../generated/model/water-dashboard-geography-dto";
import { AsyncPipe, NgIf } from "@angular/common";
import { AuthenticationService } from "../../services/authentication.service";
import { Observable, tap } from "rxjs";
import { UserDto } from "../../generated/model/user-dto";
import { RoleEnum } from "../../generated/enum/role-enum";
import { GeographyRoleEnum } from "../../generated/enum/geography-role-enum";

@Component({
    selector: "water-dashboard-nav",
    standalone: true,
    imports: [RouterOutlet, RouterLink, RouterLinkActive, IconComponent, DropdownToggleDirective, DropdownToggleCloseDirective, NgIf, AsyncPipe],
    templateUrl: "./water-dashboard-nav.component.html",
    styleUrl: "./water-dashboard-nav.component.scss",
})
export class WaterDashboardNavComponent implements OnInit {
    @Input() geography: WaterDashboardGeographyDto;
    @Output() waterAccountCreated = new EventEmitter();

    public user$: Observable<UserDto>;
    public canViewManageTools: boolean;

    constructor(private modalService: ModalService, private alertService: AlertService, private authenticationService: AuthenticationService) {}

    ngOnInit() {
        this.user$ = this.authenticationService.getCurrentUser().pipe(
            tap((user) => {
                this.canViewManageTools = user.RoleID == RoleEnum.SystemAdmin || user.GeographyUser.find((x) => x.GeographyRoleID == GeographyRoleEnum.WaterManager) != null;
            })
        );
    }

    public createWaterAccountModal(): void {
        this.modalService
            .open(
                CreateWaterAccountComponent,
                null,
                { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
                { geographyID: this.geography.GeographyID }
            )
            .instance.result.then((succeeded) => {
                this.waterAccountCreated.emit();
            });
    }

    public getGeographySlug() {
        return this.geography ? this.geography.GeographyName.toLowerCase() : "";
    }
}
