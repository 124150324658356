/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WaterAccountSimpleDto } from '././water-account-simple-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class SupportTicketUpsertDto { 
    SupportTicketID?: number;
    GeographyID?: number;
    Description?: string;
    SupportTicketStatusID?: number;
    SupportTicketPriorityID?: number;
    SupportTicketQuestionTypeID?: number;
    WaterAccount?: WaterAccountSimpleDto;
    AssignedUserID?: number;
    ContactFirstName?: string;
    ContactLastName?: string;
    ContactEmail?: string;
    ContactPhoneNumber?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface SupportTicketUpsertDtoForm { 
    SupportTicketID?: FormControl<number>;
    GeographyID?: FormControl<number>;
    Description?: FormControl<string>;
    SupportTicketStatusID?: FormControl<number>;
    SupportTicketPriorityID?: FormControl<number>;
    SupportTicketQuestionTypeID?: FormControl<number>;
    WaterAccount?: FormControl<WaterAccountSimpleDto>;
    AssignedUserID?: FormControl<number>;
    ContactFirstName?: FormControl<string>;
    ContactLastName?: FormControl<string>;
    ContactEmail?: FormControl<string>;
    ContactPhoneNumber?: FormControl<string>;
}

export class SupportTicketUpsertDtoFormControls { 
    public static SupportTicketID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Description = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SupportTicketStatusID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SupportTicketPriorityID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SupportTicketQuestionTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccount = (value: FormControlState<WaterAccountSimpleDto> | WaterAccountSimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<WaterAccountSimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AssignedUserID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactFirstName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactLastName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactEmail = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactPhoneNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
