<div class="modal-header">
    <div>
        <h3 class="section-title">Add User</h3>
    </div>
    <button type="button" class="close" (click)="close()">
        <i class="fa fa-times-circle"></i>
    </button>
</div>

<div class="modal-body">
    <form action="" class="form" [formGroup]="formGroup">
        <div class="mb-3">
            <form-field [formControl]="formGroup.controls.Email" fieldLabel="Email" placeholder="e.g. user@example.com" [type]="FormFieldType.Text" required></form-field>
        </div>
        <div class="mb-3" *ngIf="roleDropDownOptions$ | async as roleDropDownOptions">
            <form-field
                [formControl]="formGroup.controls.WaterAccountRoleID"
                fieldLabel="Water Account Role"
                [formInputOptions]="roleDropDownOptions"
                [type]="FormFieldType.Select"
                required></form-field>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()" [disabled]="!formGroup.valid || isLoadingSubmit">Send Invitation</button>
    <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
</div>
