/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllocationPlanManageDto } from '../model/allocation-plan-manage-dto';
import { BoundingBoxDto } from '../model/bounding-box-dto';
import { ParcelBulkUpdateParcelStatusDto } from '../model/parcel-bulk-update-parcel-status-dto';
import { ParcelChangesGridItemDto } from '../model/parcel-changes-grid-item-dto';
import { ParcelDetailDto } from '../model/parcel-detail-dto';
import { ParcelDisplayDto } from '../model/parcel-display-dto';
import { ParcelHistoryDto } from '../model/parcel-history-dto';
import { ParcelIndexGridDto } from '../model/parcel-index-grid-dto';
import { ParcelLayerUpdateDto } from '../model/parcel-layer-update-dto';
import { ParcelMinimalDto } from '../model/parcel-minimal-dto';
import { ParcelOwnershipUpdateDto } from '../model/parcel-ownership-update-dto';
import { ParcelPopupDto } from '../model/parcel-popup-dto';
import { ParcelSupplyDetailDto } from '../model/parcel-supply-detail-dto';
import { ParcelUpdateExpectedResultsDto } from '../model/parcel-update-expected-results-dto';
import { ParcelUpdateOwnershipRequestDto } from '../model/parcel-update-ownership-request-dto';
import { ParcelWaterSupplyDto } from '../model/parcel-water-supply-dto';
import { ParcelWithGeoJSONDto } from '../model/parcel-with-geo-json-dto';
import { ParcelZoneAssignmentFormDto } from '../model/parcel-zone-assignment-form-dto';
import { UploadParcelLayerInfoDto } from '../model/upload-parcel-layer-info-dto';
import { UploadedGdbSimpleDto } from '../model/uploaded-gdb-simple-dto';
import { WaterAccountParcelDto } from '../model/water-account-parcel-dto';
import { WellLocationDto } from '../model/well-location-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ParcelService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDEffectiveYearsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/effectiveYears`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param parcelOwnershipUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelAccountPost(geographyID: number, parcelOwnershipUpdateDto?: ParcelOwnershipUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelAccountPost(geographyID: number, parcelOwnershipUpdateDto?: ParcelOwnershipUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelAccountPost(geographyID: number, parcelOwnershipUpdateDto?: ParcelOwnershipUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelAccountPost(geographyID: number, parcelOwnershipUpdateDto?: ParcelOwnershipUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelAccountPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel/account`,
            parcelOwnershipUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelGetFeatureClassInfoGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<UploadParcelLayerInfoDto>;
    public geographiesGeographyIDParcelGetFeatureClassInfoGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadParcelLayerInfoDto>>;
    public geographiesGeographyIDParcelGetFeatureClassInfoGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadParcelLayerInfoDto>>;
    public geographiesGeographyIDParcelGetFeatureClassInfoGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelGetFeatureClassInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UploadParcelLayerInfoDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel/getFeatureClassInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param parcelBulkUpdateParcelStatusDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsBulkUpdateParcelStatusPost(geographyID: number, parcelBulkUpdateParcelStatusDto?: ParcelBulkUpdateParcelStatusDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelsBulkUpdateParcelStatusPost(geographyID: number, parcelBulkUpdateParcelStatusDto?: ParcelBulkUpdateParcelStatusDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelsBulkUpdateParcelStatusPost(geographyID: number, parcelBulkUpdateParcelStatusDto?: ParcelBulkUpdateParcelStatusDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelsBulkUpdateParcelStatusPost(geographyID: number, parcelBulkUpdateParcelStatusDto?: ParcelBulkUpdateParcelStatusDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsBulkUpdateParcelStatusPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/bulk-update-parcel-status`,
            parcelBulkUpdateParcelStatusDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsCurrentUserGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelIndexGridDto>>;
    public geographiesGeographyIDParcelsCurrentUserGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelIndexGridDto>>>;
    public geographiesGeographyIDParcelsCurrentUserGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelIndexGridDto>>>;
    public geographiesGeographyIDParcelsCurrentUserGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsCurrentUserGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelIndexGridDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsEnactGDBChangesPost(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelsEnactGDBChangesPost(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelsEnactGDBChangesPost(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelsEnactGDBChangesPost(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsEnactGDBChangesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/enactGDBChanges`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsGeojsonPost(geographyID: number, requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelWithGeoJSONDto>>;
    public geographiesGeographyIDParcelsGeojsonPost(geographyID: number, requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelWithGeoJSONDto>>>;
    public geographiesGeographyIDParcelsGeojsonPost(geographyID: number, requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelWithGeoJSONDto>>>;
    public geographiesGeographyIDParcelsGeojsonPost(geographyID: number, requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsGeojsonPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ParcelWithGeoJSONDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/geojson`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelIndexGridDto>>;
    public geographiesGeographyIDParcelsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelIndexGridDto>>>;
    public geographiesGeographyIDParcelsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelIndexGridDto>>>;
    public geographiesGeographyIDParcelsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelIndexGridDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsGetExpectedResultsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelUpdateExpectedResultsDto>;
    public geographiesGeographyIDParcelsGetExpectedResultsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelUpdateExpectedResultsDto>>;
    public geographiesGeographyIDParcelsGetExpectedResultsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelUpdateExpectedResultsDto>>;
    public geographiesGeographyIDParcelsGetExpectedResultsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsGetExpectedResultsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelUpdateExpectedResultsDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/getExpectedResults`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsLatestEffectiveYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public geographiesGeographyIDParcelsLatestEffectiveYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public geographiesGeographyIDParcelsLatestEffectiveYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public geographiesGeographyIDParcelsLatestEffectiveYearPost(geographyID: number, requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsLatestEffectiveYearPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/latest-effective-year`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsParcelIDAllocationPlansGet(geographyID: number, parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AllocationPlanManageDto>>;
    public geographiesGeographyIDParcelsParcelIDAllocationPlansGet(geographyID: number, parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AllocationPlanManageDto>>>;
    public geographiesGeographyIDParcelsParcelIDAllocationPlansGet(geographyID: number, parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AllocationPlanManageDto>>>;
    public geographiesGeographyIDParcelsParcelIDAllocationPlansGet(geographyID: number, parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsParcelIDAllocationPlansGet.');
        }

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling geographiesGeographyIDParcelsParcelIDAllocationPlansGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AllocationPlanManageDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/${encodeURIComponent(String(parcelID))}/allocation-plans`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param parcelLayerUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsPreviewGDBChangesPost(geographyID: number, parcelLayerUpdateDto?: ParcelLayerUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelsPreviewGDBChangesPost(geographyID: number, parcelLayerUpdateDto?: ParcelLayerUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelsPreviewGDBChangesPost(geographyID: number, parcelLayerUpdateDto?: ParcelLayerUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelsPreviewGDBChangesPost(geographyID: number, parcelLayerUpdateDto?: ParcelLayerUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsPreviewGDBChangesPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/previewGDBChanges`,
            parcelLayerUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsReviewChangesGridItemsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelChangesGridItemDto>>;
    public geographiesGeographyIDParcelsReviewChangesGridItemsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelChangesGridItemDto>>>;
    public geographiesGeographyIDParcelsReviewChangesGridItemsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelChangesGridItemDto>>>;
    public geographiesGeographyIDParcelsReviewChangesGridItemsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsReviewChangesGridItemsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelChangesGridItemDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/review-changes-grid-items`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsSearchSearchStringGet(geographyID: number, searchString: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelDisplayDto>>;
    public geographiesGeographyIDParcelsSearchSearchStringGet(geographyID: number, searchString: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelDisplayDto>>>;
    public geographiesGeographyIDParcelsSearchSearchStringGet(geographyID: number, searchString: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelDisplayDto>>>;
    public geographiesGeographyIDParcelsSearchSearchStringGet(geographyID: number, searchString: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsSearchSearchStringGet.');
        }

        if (searchString === null || searchString === undefined) {
            throw new Error('Required parameter searchString was null or undefined when calling geographiesGeographyIDParcelsSearchSearchStringGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelDisplayDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/search/${encodeURIComponent(String(searchString))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsUploadedGdbLatestGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<UploadedGdbSimpleDto>;
    public geographiesGeographyIDParcelsUploadedGdbLatestGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadedGdbSimpleDto>>;
    public geographiesGeographyIDParcelsUploadedGdbLatestGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadedGdbSimpleDto>>;
    public geographiesGeographyIDParcelsUploadedGdbLatestGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsUploadedGdbLatestGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UploadedGdbSimpleDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/uploaded-gdb/latest`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsWaterAccountStartYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public geographiesGeographyIDParcelsWaterAccountStartYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public geographiesGeographyIDParcelsWaterAccountStartYearPost(geographyID: number, requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public geographiesGeographyIDParcelsWaterAccountStartYearPost(geographyID: number, requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsWaterAccountStartYearPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<number>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels/water-account/start-year`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelsWaterSupplyYearGet(geographyID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelWaterSupplyDto>>;
    public geographiesGeographyIDParcelsWaterSupplyYearGet(geographyID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelWaterSupplyDto>>>;
    public geographiesGeographyIDParcelsWaterSupplyYearGet(geographyID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelWaterSupplyDto>>>;
    public geographiesGeographyIDParcelsWaterSupplyYearGet(geographyID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelsWaterSupplyYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelsWaterSupplyYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelWaterSupplyDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcels-water-supply/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUploadParcelGdbPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/upload-parcel-gdb`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterAccountID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelMinimalDto>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelMinimalDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelMinimalDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet(geographyID: number, waterAccountID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/${encodeURIComponent(String(waterAccountID))}/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BoundingBoxDto>(`${this.basePath}/parcels/boundingBox`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param parcelZoneAssignmentFormDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDEditZoneAssignmentsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/edit-zone-assignments`,
            parcelZoneAssignmentFormDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelMinimalDto>;
    public parcelsParcelIDGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelMinimalDto>>;
    public parcelsParcelIDGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelMinimalDto>>;
    public parcelsParcelIDGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelMinimalDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelSupplyDetailDto>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelSupplyDetailDto>>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelSupplyDetailDto>>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDGetSupplyEntriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelSupplyDetailDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/getSupplyEntries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelHistoryDto>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelHistoryDto>>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelHistoryDto>>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelHistoryDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelPopupDto>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelPopupDto>>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelPopupDto>>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDMapPopupGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelPopupDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/map-popup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param parcelUpdateOwnershipRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDOwnershipPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/ownership`,
            parcelUpdateOwnershipRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param waterAccountID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDUpdateWaterAccountWaterAccountIDPost.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling parcelsParcelIDUpdateWaterAccountWaterAccountIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/update-water-account/${encodeURIComponent(String(waterAccountID))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountParcelDto>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountParcelDto>>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountParcelDto>>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDWaterAccountParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountParcelDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/waterAccountParcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellLocationDto>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellLocationDto>>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellLocationDto>>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellLocationDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelDetailDto>;
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelDetailDto>>;
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelDetailDto>>;
    public parcelsParcelIDZonesGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDZonesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelDetailDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/zones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsReviewPut(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/parcels/review`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelDisplayDto>>;
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelDisplayDto>>>;
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelDisplayDto>>>;
    public parcelsSearchSearchStringGet(searchString: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchString === null || searchString === undefined) {
            throw new Error('Required parameter searchString was null or undefined when calling parcelsSearchSearchStringGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelDisplayDto>>(`${this.basePath}/parcels/search/${encodeURIComponent(String(searchString))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDParcelMinimalsGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelMinimalDto>>;
    public waterAccountsWaterAccountIDParcelMinimalsGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelMinimalDto>>>;
    public waterAccountsWaterAccountIDParcelMinimalsGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelMinimalDto>>>;
    public waterAccountsWaterAccountIDParcelMinimalsGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDParcelMinimalsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelMinimalDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/parcel-minimals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
