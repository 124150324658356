import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, IsActiveMatchOptions, RouterLink, RouterOutlet, RouterLinkActive, NavigationEnd } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { Subscription, filter, startWith, switchMap } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { DashboardMenu, DashboardMenuComponent } from "src/app/shared/components/dashboard-menu/dashboard-menu.component";
import { ParcelService } from "src/app/shared/generated/api/parcel.service";
import { ParcelDetailDto } from "src/app/shared/generated/model/parcel-detail-dto";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { GeographyLogoComponent } from "../../../shared/components/geography-logo/geography-logo.component";
import { IconComponent } from "../../../shared/components/icon/icon.component";
import { PageHeaderComponent } from "../../../shared/components/page-header/page-header.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { LoadingDirective } from "src/app/shared/directives/loading.directive";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { FormsModule } from "@angular/forms";
import { SearchParcelsComponent } from "../../../shared/components/search-parcels/search-parcels.component";

import { ParcelMinimalDto } from "src/app/shared/generated/model/parcel-minimal-dto";
import { AllocationPlanService } from "src/app/shared/generated/api/allocation-plan.service";
import { DropdownToggleDirective } from "src/app/shared/directives/dropdown-toggle.directive";

@Component({
    selector: "parcel-detail-layout",
    standalone: true,
    imports: [
        SearchParcelsComponent,
        NgIf,
        RouterLink,
        GeographyLogoComponent,
        IconComponent,
        FormsModule,
        RouterLinkActive,
        DashboardMenuComponent,
        RouterOutlet,
        PageHeaderComponent,
        LoadingDirective,
        AsyncPipe,
        DropdownToggleDirective,
    ],

    templateUrl: "./parcel-detail-layout.component.html",
    styleUrl: "./parcel-detail-layout.component.scss",
})
export class ParcelDetailLayoutComponent implements OnInit, OnDestroy {
    @ViewChild("parcelSupplyGrid") parcelSupplyGrid: AgGridAngular;
    public currentParcel: ParcelMinimalDto;
    public userHasOneGeography = false;
    public geographyID: number;
    public navigationSubscription: Subscription = Subscription.EMPTY;

    public showAllocationPlan: boolean;
    public dashboardMenu: DashboardMenu;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private parcelService: ParcelService,
        private allocationPlanService: AllocationPlanService
    ) {}

    ngOnInit(): void {
        const parcelID = parseInt(this.route.snapshot.paramMap.get(routeParams.parcelID));
        this.parcelService.parcelsParcelIDGet(parcelID).subscribe((parcel) => {
            this.currentParcel = parcel;
            this.navigationSubscription = this.getNavigationSubscription();
            this.geographyID = parcel.GeographyID;
            this.allocationPlanService.geographiesGeographyIDAllocationPlanConfigurationGet(parcel.GeographyID).subscribe((x) => {
                this.showAllocationPlan = x.IsVisibleToLandowners ?? false;
                this.dashboardMenu = this.buildMenu(parcel);
            });
        });
    }

    ngOnDestroy(): void {
        this.navigationSubscription.unsubscribe();
    }

    public viewingParcel: boolean = false;

    private getNavigationSubscription(): Subscription {
        return this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(null as any),
                switchMap((e) => {
                    if (this.route.firstChild) {
                        return this.route.firstChild.paramMap;
                    }
                    return this.route.paramMap;
                })
            )
            .subscribe((paramMap) => {
                // do something on each navigation event
                const newParcelID = parseInt(paramMap.get(routeParams.parcelID));
                this.viewingParcel = !isNaN(newParcelID) ? true : false;
                this.parcelService.parcelsParcelIDGet(newParcelID).subscribe((parcel) => {
                    this.currentParcel = parcel;
                    this.geographyID = parcel.GeographyID;
                    this.allocationPlanService.geographiesGeographyIDAllocationPlanConfigurationGet(parcel.GeographyID).subscribe((x) => {
                        this.showAllocationPlan = x.IsVisibleToLandowners ?? false;
                        this.dashboardMenu = this.buildMenu(parcel);
                    });
                });
            });
    }

    buildMenu(parcel: ParcelDetailDto): DashboardMenu {
        const parcelID = parcel.ParcelID;
        const menu = {
            menuItems: [
                {
                    title: parcel.ParcelNumber,
                    icon: "Parcels",
                    routerLink: ["/water-dashboard", "parcels", parcelID],
                    isDropdown: true,
                    routerLinkActiveOptions: {
                        matrixParams: "ignored",
                        queryParams: "ignored",
                        fragment: "exact",
                        paths: "subset",
                    },
                    hidden: true,
                    menuItems: [
                        {
                            title: "Parcel Details",
                            routerLink: ["/parcels", parcelID, "detail"],
                        },
                        {
                            title: "Water Measurements",
                            routerLink: ["/parcels", parcelID, "detail"],
                            fragment: "water-measurements",
                        },
                        {
                            title: "Allocation Plan",
                            routerLink: ["/parcels", parcelID, "detail"],
                            fragment: "allocation-plan",
                            isDisabled: !this.showAllocationPlan,
                        },
                        {
                            title: "Supply Activity",
                            routerLink: ["/parcels", parcelID, "detail"],
                            fragment: "ledger-activity",
                        },
                        {
                            title: "Admin Panel",
                            routerLink: ["/parcels", parcelID, "admin-panel"],
                            isDisabled: !(
                                this.authenticationService.isCurrentUserAnAdministrator() ||
                                this.authenticationService.currentUserHasGeographyFlagForGeographyID(FlagEnum.HasManagerDashboard, this.currentParcel.GeographyID)
                            ),
                        },
                    ],
                },
                {
                    title: "Water Accounts",
                    icon: "WaterAccounts",
                    routerLink: ["/water-dashboard/water-accounts"],
                },
                {
                    title: "Parcels",
                    icon: "Parcels",
                    routerLink: ["/water-dashboard/parcels"],
                },
                {
                    title: "Wells",
                    icon: "Wells",
                    routerLink: ["/water-dashboard/wells"],
                },
                {
                    title: "Support & Contact",
                    icon: "Question",
                    routerLink: ["/request-support"],
                    queryParams: { GeographyID: parcel.GeographyID.toString() },
                },
            ],
        } as DashboardMenu;

        menu.menuItems.forEach((menuItem) => {
            menuItem.menuItems?.forEach((childItem) => {
                const urltree = this.router.createUrlTree(childItem.routerLink as any[]);
                const childRouteIsActive = this.router.isActive(
                    urltree,
                    childItem.routerLinkActiveOptions
                        ? childItem.routerLinkActiveOptions
                        : ({ paths: "exact", queryParams: "ignored", matrixParams: "ignored" } as IsActiveMatchOptions)
                );
                if (childRouteIsActive) {
                    menuItem.isExpanded = true;
                }
            });
        });

        return menu;
    }

    changedParcel(parcel: ParcelMinimalDto): void {
        if (parcel && parcel.ParcelID != undefined && parcel.ParcelID != this.currentParcel.ParcelID) {
            const url = this.router.url.split("#")[0].split("/");
            url[2] = parcel.ParcelID.toString();
            this.router.navigate(url, { preserveFragment: true, replaceUrl: true });
        }
    }
}
