<page-header *ngIf="geography$ | async as geography" icon="Question" [templateTitleAppend]="templateTitleAppend" [templateRight]="requestSupport" pageTitle="Support & Contact">
    <ng-template #templateTitleAppend>
        <name-tag [name]="geography.GeographyName" class="mb-2"></name-tag>
    </ng-template>
    <ng-template #requestSupport>
        <button class="btn btn-secondary" (click)="setRouteQueryParams(geography.GeographyName)">Request Support</button>
    </ng-template>
</page-header>

<div class="page-body grid-12">
    <app-alert-display></app-alert-display>

    <div class="copy copy-2">
        <custom-rich-text *ngIf="geography$ | async as geography" [customRichTextTypeID]="customRichTextTypeID" [geographyID]="geography.GeographyID"></custom-rich-text>
    </div>
</div>
