<div *ngIf="!isLoading; else loading">
    <qanat-grid
        height="500px"
        [rowData]="measurements"
        [columnDefs]="colDefs"
        [sizeColumnsToFitGrid]="true"
        downloadFileName="parcel-water-measurements"
        [pagination]="true"
        [paginationPageSize]="100"
        (gridReady)="onGridReady($event)"></qanat-grid>
</div>
<ng-template #loading>
    <div [loadingSpinner]="{ loadingHeight: 500, isLoading: true }"></div>
</ng-template>
