/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BoundingBoxDto } from '././bounding-box-dto';
import { UserDto } from '././user-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class GeographyForAdminEditorsDto { 
    GeographyID: number;
    GeographyDisplayName: string;
    StartYear: number;
    DefaultDisplayYear: number;
    APNRegexPattern: string;
    APNRegexDisplay: string;
    LandownerDashboardSupplyLabel: string;
    LandownerDashboardUsageLabel: string;
    ContactEmail?: string;
    ContactPhoneNumber?: string;
    DisplayUsageGeometriesAsField: boolean;
    AllowLandownersToRequestAccountChanges: boolean;
    ShowSupplyOnWaterBudgetComponent: boolean;
    WaterBudgetSlotAHeader?: string;
    WaterBudgetSlotAWaterMeasurementTypeID?: number;
    WaterBudgetSlotBHeader?: string;
    WaterBudgetSlotBWaterMeasurementTypeID?: number;
    WaterBudgetSlotCHeader?: string;
    WaterBudgetSlotCWaterMeasurementTypeID?: number;
    BoundingBox?: BoundingBoxDto;
    WaterManagers?: Array<UserDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface GeographyForAdminEditorsDtoForm { 
    GeographyID: FormControl<number>;
    GeographyDisplayName: FormControl<string>;
    StartYear: FormControl<number>;
    DefaultDisplayYear: FormControl<number>;
    APNRegexPattern: FormControl<string>;
    APNRegexDisplay: FormControl<string>;
    LandownerDashboardSupplyLabel: FormControl<string>;
    LandownerDashboardUsageLabel: FormControl<string>;
    ContactEmail?: FormControl<string>;
    ContactPhoneNumber?: FormControl<string>;
    DisplayUsageGeometriesAsField: FormControl<boolean>;
    AllowLandownersToRequestAccountChanges: FormControl<boolean>;
    ShowSupplyOnWaterBudgetComponent: FormControl<boolean>;
    WaterBudgetSlotAHeader?: FormControl<string>;
    WaterBudgetSlotAWaterMeasurementTypeID?: FormControl<number>;
    WaterBudgetSlotBHeader?: FormControl<string>;
    WaterBudgetSlotBWaterMeasurementTypeID?: FormControl<number>;
    WaterBudgetSlotCHeader?: FormControl<string>;
    WaterBudgetSlotCWaterMeasurementTypeID?: FormControl<number>;
    BoundingBox?: FormControl<BoundingBoxDto>;
    WaterManagers?: FormControl<Array<UserDto>>;
}

export class GeographyForAdminEditorsDtoFormControls { 
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static GeographyDisplayName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(0),
                Validators.maxLength(100),
            ],
        }
    );
    public static StartYear = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static DefaultDisplayYear = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static APNRegexPattern = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(0),
                Validators.maxLength(100),
            ],
        }
    );
    public static APNRegexDisplay = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(0),
                Validators.maxLength(50),
            ],
        }
    );
    public static LandownerDashboardSupplyLabel = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(0),
                Validators.maxLength(200),
            ],
        }
    );
    public static LandownerDashboardUsageLabel = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(0),
                Validators.maxLength(200),
            ],
        }
    );
    public static ContactEmail = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
                Validators.email,
            ],
        }
    );
    public static ContactPhoneNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
                Validators.minLength(10),
                Validators.maxLength(10),
            ],
        }
    );
    public static DisplayUsageGeometriesAsField = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static AllowLandownersToRequestAccountChanges = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static ShowSupplyOnWaterBudgetComponent = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static WaterBudgetSlotAHeader = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterBudgetSlotAWaterMeasurementTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterBudgetSlotBHeader = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterBudgetSlotBWaterMeasurementTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterBudgetSlotCHeader = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterBudgetSlotCWaterMeasurementTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static BoundingBox = (value: FormControlState<BoundingBoxDto> | BoundingBoxDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<BoundingBoxDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterManagers = (value: FormControlState<Array<UserDto>> | Array<UserDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<UserDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
