<allocation-plan-table-row [years]="years" [placeSlotAtYear]="startYear" [spanYears]="totalSpan">
    <ng-template #period>
        <div class="period-metadata">
            <h4>{{ allocationPlanPeriod.AllocationPeriodName }}</h4>
            <div class="links mt-1" *ngIf="!readonly">
                <button type="button" class="btn btn-text" (click)="openEditModal()">Edit</button>
                <button type="button" class="btn btn-text text-danger" (click)="openDeleteModal()">Delete</button>
            </div>
        </div>
    </ng-template>
    <ng-template #slot>
        <period-body [allocationPlanPeriod]="allocationPlanPeriod" [zoneColor]="allocationPlanManageDto.Zone.ZoneColor"></period-body>
    </ng-template>
</allocation-plan-table-row>
