import { Component, OnInit } from "@angular/core";

@Component({
    selector: "scenarios-configure",
    templateUrl: "./scenarios-configure.component.html",
    styleUrls: ["./scenarios-configure.component.scss"],
    standalone: true,
})
export class ScenariosConfigureComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
