<div class="option">
    <div class="option-header flex-between">
        <h3 class="option-title">
            <a *ngIf="routerLinkValue; else noLinkTitle" [routerLink]="[routerLinkValue]" [class.disabled]="linkDisabled">
                {{ title }}
            </a>
            <ng-template #noLinkTitle>
                {{ title }}
            </ng-template>
        </h3>
        <ng-container *ngIf="!hideToggle">
            <name-tag *ngIf="toggleChecked" name="enabled" color="#ffa62b"></name-tag>
            <name-tag *ngIf="!toggleChecked" name="Disabled" color="#fafafa" textColor="#8a8a8a"> </name-tag>
        </ng-container>
    </div>

    <div class="option-body">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="option-footer flex-end" [class.disabled]="linkDisabled" *ngIf="routerLinkValue">
        <a [routerLink]="[routerLinkValue]" class="btn btn-primary-outline btn-sm">Options</a>
    </div>
</div>
