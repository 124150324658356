<page-header
    *ngIf="waterAccount"
    [pageTitle]="'#' + waterAccount.WaterAccountNumber"
    [templateTitleAppend]="templateTitleAppend"
    [templateRight]="templateRight"
    icon="WaterAccounts"
    preTitle="Account Activity">
    <ng-template #templateTitleAppend>
        <span
            class="water-account-name"
            *ngIf="waterAccount.WaterAccountName?.length > 0 && waterAccount.WaterAccountName != waterAccount.WaterAccountNumber"
            title="{{ waterAccount.WaterAccountName }}">
            {{ waterAccount.WaterAccountName }}
        </span>

        <name-tag
            *ngIf="allocationPlans?.length > 0"
            [name]="allocationPlans[0].ZoneName"
            [color]="allocationPlans[0].ZoneColor"
            [routerLink]="['/geographies', waterAccount.Geography.GeographyName.toLowerCase(), 'allocation-plans', allocationPlans[0].WaterTypeSlug, allocationPlans[0].ZoneSlug]"
            class="zone-tag"
            title="Allocation Plan Zone"></name-tag>
    </ng-template>
    <ng-template #templateRight></ng-template>
</page-header>

<div class="page-body statistics activity grid-12" *ngIf="waterAccount && !isLoading; else isLoadingTemplate">
    <div class="statistics__filters" *ngIf="waterAccount">
        <reporting-period-select
            [geographyID]="geographyID"
            [defaultDisplayYear]="selectedYear"
            (selectionChanged)="updateDashboardForSelectedYear($event)"></reporting-period-select>

        <div class="statistics__filter units">
            <h5 class="statistics__filter-label">Units</h5>
            <button-group>
                <a class="button-group__item" [ngClass]="{ active: getShowAcresFeet() == false }" (click)="changeUnits(false)"> ac-ft/ac </a>
                <a class="button-group__item" [ngClass]="{ active: getShowAcresFeet() == true }" (click)="changeUnits(true)"> ac-ft </a>
            </button-group>
        </div>
    </div>
    <div class="group group-2">
        <div class="statistics__module activity__module">
            <div class="statistics__module-header">
                <h3 class="statistics__title-small">Last Transaction</h3>
            </div>

            <div class="stat">
                <h4 class="stat__value activity__value credit">
                    {{ (getShowAcresFeet() ? getMostRecentTransactionAmount() : getMostRecentTransactionAmount() / getMostRecentTransactionParcelArea()) | number : "1.2-2" }}
                    <span class="unit">
                        {{ getShowAcresFeet() ? acresFeetUnits : acresFeetAcreUnits }}
                    </span>
                </h4>
                <p class="stat__meta activity-note" *ngIf="mostRecentTransaction">Effective {{ getMostRecentTransactionEffectiveDate() }}</p>
            </div>
        </div>
    </div>

    <div class="statistics__filter units flex-center">
        <button-group>
            <a class="button-group__item" [ngClass]="{ active: showGrid == false }" (click)="changeShowGrid(false)"> Recent Transactions </a>
            <a class="button-group__item" [ngClass]="{ active: showGrid == true }" (click)="changeShowGrid(true)"> All Transactions </a>
        </button-group>
    </div>

    <div class="activity__ledger" *ngIf="!showGrid">
        <div class="activity__ledger-header">
            <div class="effective-date-header">Effective Date</div>
            <div class="transaction-header">Transaction</div>
            <div class="quantity-header">Quantity</div>
        </div>

        <div class="activity__ledger-body" *ngFor="let transaction of transactions">
            <div class="activity__ledger-item open-et-rollup">
                <div class="effective-date">
                    {{ transaction.EffectiveDate | date : "MMM d, yyyy" : "+0000" }}
                </div>
                <div class="transaction">
                    <div class="icon">
                        <div class="icon__fpo debit">
                            <i class="fa fa-database"></i>
                        </div>
                    </div>

                    <div class="info">
                        <h4 class="activity__ledger-title large">
                            {{ transaction.ParcelSupplies[0].WaterType.WaterTypeName }} Supply
                            <span *ngIf="transaction.ParcelSupplies[0].User" class="method"> Added by Water Manager </span>
                        </h4>

                        <div class="activity__ledger-meta">
                            <span *ngIf="transaction.ParcelCount === 1" class="parcel">
                                <strong>Parcel:</strong>
                                {{ transaction.ParcelSupplies[0].Parcel.ParcelNumber }}
                            </span>
                            <span *ngIf="transaction.ParcelCount > 1" class="parcel">
                                <strong> {{ transaction.ParcelCount }} Parcel{{ transaction.ParcelCount > 1 ? "s" : "" }} </strong>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="quantity">
                    <div class="amount">
                        {{ (getShowAcresFeet() ? transaction.TransactionAmount : transaction.TransactionAmount / totalAcreage) | number : "1.2-2" }}
                        <span class="unit">
                            {{ getShowAcresFeet() ? acresFeetUnits : acresFeetAcreUnits }}
                        </span>
                    </div>
                    <div class="balance">
                        <strong>Balance:</strong>
                        {{
                            (getShowAcresFeet()
                                ? ParcelSuppliesBalance.get(transaction.ParcelActivityKey)
                                : ParcelSuppliesBalance.get(transaction.ParcelActivityKey) / transaction.ParcelArea
                            ) | number : "1.2-2"
                        }}
                        <span class="unit">
                            {{ getShowAcresFeet() ? acresFeetUnits : acresFeetAcreUnits }}
                        </span>
                    </div>

                    <div class="toggle" [expandCollapse]="subLedger" *ngIf="transaction.ParcelSupplies?.length > 1">
                        <i class="fas fa-angle-up"></i>
                    </div>
                </div>
                <div class="sub-ledger" #subLedger>
                    <div class="table-responsive">
                        <table class="transactions">
                            <thead>
                                <tr>
                                    <th class="transactions__apn">APN</th>
                                    <th class="transactions__effective">Effective Date</th>
                                    <th class="transactions__transaction">Transaction Date</th>
                                    <th class="transactions__description">Transaction</th>
                                    <th class="transactions__amount">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="transactions__item" *ngFor="let parcelSupply of transaction.ParcelSupplies; let index = index">
                                    <td class="transactions__apn">
                                        {{ parcelSupply.Parcel.ParcelNumber }}
                                    </td>
                                    <td class="transactions__effective">
                                        {{ parcelSupply.EffectiveDate | date : "M/d/yyyy" : "+0000" }}
                                    </td>
                                    <td class="transactions__transaction">
                                        {{ parcelSupply.TransactionDate | date : "M/d/yyyy" : "+0000" }}
                                    </td>
                                    <td class="transactions__description">
                                        <div>
                                            {{ isInitialEstimate(transaction, parcelSupply.Parcel.ParcelID, index) ? "Supply Estimate" : "Correction" }}
                                        </div>
                                    </td>
                                    <td class="transactions__amount">
                                        {{ getTransactionAmountToDisplay(parcelSupply) | number : "1.2-2" }}
                                        <span class="unit">
                                            {{ getShowAcresFeet() ? acresFeetUnits : acresFeetAcreUnits }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="transactions-grid" *ngIf="showGrid">
        <qanat-grid
            height="800px"
            [rowData]="parcelSupplyDtos"
            [columnDefs]="columnDefs"
            downloadFileName="{{ selectedYear }}-transaction-history-{{ waterAccount.WaterAccountNumber }}"
            [pagination]="true"
            [paginationPageSize]="100"></qanat-grid>
    </div>
</div>

<ng-template #isLoadingTemplate>
    <div [loadingSpinner]="{ isLoading: true }" style="margin-top: 10rem"></div>
</ng-template>
