<div class="full-width-page">
    <page-header icon="Review" [customRichTextTypeID]="customRichTextId" [templateAbove]="templateAbove"></page-header>

    <ng-template #templateAbove>
        <div class="back">
            <a routerLink="/water-dashboard/wells" class="back__link">Back to All Wells</a>
        </div>
    </ng-template>

    <div class="page-body">
        <app-alert-display></app-alert-display>
        <qanat-grid
            *ngIf="wellsToReview$ | async as wells"
            [rowData]="wells"
            [columnDefs]="colDefs"
            downloadFileName="submitted-wells"
            [colIDsToExclude]="['0']"
            [sizeColumnsToFitGrid]="true"></qanat-grid>
    </div>
</div>
