/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MLRPIncentiveDto } from '././mlrp-incentive-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class FeeCalculatorInputDto { 
    WaterAccountID: number;
    ReportingYear: number;
    FeeStructureID: number;
    SurfaceWaterDelivered?: number;
    SurfaceWaterIrrigationEfficiency?: number;
    MLRPIncentives?: Array<MLRPIncentiveDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface FeeCalculatorInputDtoForm { 
    WaterAccountID: FormControl<number>;
    ReportingYear: FormControl<number>;
    FeeStructureID: FormControl<number>;
    SurfaceWaterDelivered?: FormControl<number>;
    SurfaceWaterIrrigationEfficiency?: FormControl<number>;
    MLRPIncentives?: FormControl<Array<MLRPIncentiveDto>>;
}

export class FeeCalculatorInputDtoFormControls { 
    public static WaterAccountID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static ReportingYear = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static FeeStructureID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static SurfaceWaterDelivered = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SurfaceWaterIrrigationEfficiency = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MLRPIncentives = (value: FormControlState<Array<MLRPIncentiveDto>> | Array<MLRPIncentiveDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<MLRPIncentiveDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
