<page-header [templateRight]="templateRight" [customRichTextTypeID]="customRichTextID"></page-header>
<ng-template #templateRight>
    <button class="btn btn-primary" (click)="openCreateNewModal()">+ Add FAQ</button>
</ng-template>

<div class="page-body grid-12">
    <app-alert-display></app-alert-display>

    <qanat-grid
        *ngIf="frequentlyAskedQuestions$ | async as faqs"
        height="450px"
        [rowData]="faqs"
        [columnDefs]="colDefs"
        downloadFileName="faqs"
        (gridReady)="onGridReady($event)"></qanat-grid>
</div>
