/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddWellMeterRequestDto } from '../model/add-well-meter-request-dto';
import { BoundingBoxDto } from '../model/bounding-box-dto';
import { ManagerWellUpdateRequestDto } from '../model/manager-well-update-request-dto';
import { MeterGridDto } from '../model/meter-grid-dto';
import { ReferenceWellManageGridDto } from '../model/reference-well-manage-grid-dto';
import { ReferenceWellMapMarkerDto } from '../model/reference-well-map-marker-dto';
import { RemoveWellMeterRequestDto } from '../model/remove-well-meter-request-dto';
import { WellDetailDto } from '../model/well-detail-dto';
import { WellIrrigatedParcelsRequestDto } from '../model/well-irrigated-parcels-request-dto';
import { WellIrrigatedParcelsResponseDto } from '../model/well-irrigated-parcels-response-dto';
import { WellLocationDto } from '../model/well-location-dto';
import { WellLocationPreviewDto } from '../model/well-location-preview-dto';
import { WellMinimalDto } from '../model/well-minimal-dto';
import { WellPopupDto } from '../model/well-popup-dto';
import { WellStatusSimpleDto } from '../model/well-status-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WellService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDReferenceWellsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ReferenceWellMapMarkerDto>>;
    public geographiesGeographyIDReferenceWellsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReferenceWellMapMarkerDto>>>;
    public geographiesGeographyIDReferenceWellsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReferenceWellMapMarkerDto>>>;
    public geographiesGeographyIDReferenceWellsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDReferenceWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ReferenceWellMapMarkerDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/reference-wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDReferenceWellsGridGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ReferenceWellManageGridDto>>;
    public geographiesGeographyIDReferenceWellsGridGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReferenceWellManageGridDto>>>;
    public geographiesGeographyIDReferenceWellsGridGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReferenceWellManageGridDto>>>;
    public geographiesGeographyIDReferenceWellsGridGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDReferenceWellsGridGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ReferenceWellManageGridDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/reference-wells/grid`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUploadReferenceWellsPost(geographyID: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUploadReferenceWellsPost(geographyID: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUploadReferenceWellsPost(geographyID: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUploadReferenceWellsPost(geographyID: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUploadReferenceWellsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/upload-reference-wells`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUploadWellsPost(geographyID: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUploadWellsPost(geographyID: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUploadWellsPost(geographyID: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUploadWellsPost(geographyID: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUploadWellsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/upload-wells`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellsCurrentUserGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellMinimalDto>>;
    public geographiesGeographyIDWellsCurrentUserGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellMinimalDto>>>;
    public geographiesGeographyIDWellsCurrentUserGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellMinimalDto>>>;
    public geographiesGeographyIDWellsCurrentUserGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellsCurrentUserGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/wells/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellMinimalDto>>;
    public geographiesGeographyIDWellsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellMinimalDto>>>;
    public geographiesGeographyIDWellsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellMinimalDto>>>;
    public geographiesGeographyIDWellsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellsLocationGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellLocationDto>>;
    public geographiesGeographyIDWellsLocationGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellLocationDto>>>;
    public geographiesGeographyIDWellsLocationGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellLocationDto>>>;
    public geographiesGeographyIDWellsLocationGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellsLocationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellLocationDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/wells/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellMinimalDto>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellMinimalDto>>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellMinimalDto>>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellMinimalDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellStatusGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellStatusSimpleDto>>;
    public wellStatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellStatusSimpleDto>>>;
    public wellStatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellStatusSimpleDto>>>;
    public wellStatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellStatusSimpleDto>>(`${this.basePath}/well-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsBoundingBoxPost(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public wellsBoundingBoxPost(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public wellsBoundingBoxPost(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public wellsBoundingBoxPost(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BoundingBoxDto>(`${this.basePath}/wells/bounding-box`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDDetailsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellDetailDto>;
    public wellsWellIDDetailsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellDetailDto>>;
    public wellsWellIDDetailsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellDetailDto>>;
    public wellsWellIDDetailsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDDetailsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellDetailDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellMinimalDto>;
    public wellsWellIDGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellMinimalDto>>;
    public wellsWellIDGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellMinimalDto>>;
    public wellsWellIDGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellMinimalDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellIrrigatedParcelsResponseDto>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellIrrigatedParcelsResponseDto>>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellIrrigatedParcelsResponseDto>>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDIrrigatedParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellIrrigatedParcelsResponseDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/irrigated-parcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellIrrigatedParcelsRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDIrrigatedParcelsPut(wellID: number, wellIrrigatedParcelsRequestDto?: WellIrrigatedParcelsRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, wellIrrigatedParcelsRequestDto?: WellIrrigatedParcelsRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, wellIrrigatedParcelsRequestDto?: WellIrrigatedParcelsRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, wellIrrigatedParcelsRequestDto?: WellIrrigatedParcelsRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDIrrigatedParcelsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/irrigated-parcels`,
            wellIrrigatedParcelsRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDLocationGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellLocationDto>;
    public wellsWellIDLocationGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellLocationDto>>;
    public wellsWellIDLocationGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellLocationDto>>;
    public wellsWellIDLocationGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDLocationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellLocationDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDLocationPreviewPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'body', reportProgress?: boolean): Observable<WellLocationPreviewDto>;
    public wellsWellIDLocationPreviewPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellLocationPreviewDto>>;
    public wellsWellIDLocationPreviewPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellLocationPreviewDto>>;
    public wellsWellIDLocationPreviewPut(wellID: number, wellLocationDto?: WellLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDLocationPreviewPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WellLocationPreviewDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/location/preview`,
            wellLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDLocationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/location`,
            wellLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param addWellMeterRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDMetersPost(wellID: number, addWellMeterRequestDto?: AddWellMeterRequestDto, observe?: 'body', reportProgress?: boolean): Observable<MeterGridDto>;
    public wellsWellIDMetersPost(wellID: number, addWellMeterRequestDto?: AddWellMeterRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterGridDto>>;
    public wellsWellIDMetersPost(wellID: number, addWellMeterRequestDto?: AddWellMeterRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterGridDto>>;
    public wellsWellIDMetersPost(wellID: number, addWellMeterRequestDto?: AddWellMeterRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDMetersPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeterGridDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/meters`,
            addWellMeterRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param removeWellMeterRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDMetersPut(wellID: number, removeWellMeterRequestDto?: RemoveWellMeterRequestDto, observe?: 'body', reportProgress?: boolean): Observable<MeterGridDto>;
    public wellsWellIDMetersPut(wellID: number, removeWellMeterRequestDto?: RemoveWellMeterRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterGridDto>>;
    public wellsWellIDMetersPut(wellID: number, removeWellMeterRequestDto?: RemoveWellMeterRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterGridDto>>;
    public wellsWellIDMetersPut(wellID: number, removeWellMeterRequestDto?: RemoveWellMeterRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDMetersPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MeterGridDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/meters`,
            removeWellMeterRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDPopupGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellPopupDto>;
    public wellsWellIDPopupGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellPopupDto>>;
    public wellsWellIDPopupGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellPopupDto>>;
    public wellsWellIDPopupGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDPopupGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellPopupDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/popup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param managerWellUpdateRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDPut(wellID: number, managerWellUpdateRequestDto?: ManagerWellUpdateRequestDto, observe?: 'body', reportProgress?: boolean): Observable<WellMinimalDto>;
    public wellsWellIDPut(wellID: number, managerWellUpdateRequestDto?: ManagerWellUpdateRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellMinimalDto>>;
    public wellsWellIDPut(wellID: number, managerWellUpdateRequestDto?: ManagerWellUpdateRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellMinimalDto>>;
    public wellsWellIDPut(wellID: number, managerWellUpdateRequestDto?: ManagerWellUpdateRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WellMinimalDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            managerWellUpdateRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
