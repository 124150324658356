/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ParcelSearchResultWithMatchedFieldsDto } from '././parcel-search-result-with-matched-fields-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ParcelSearchSummaryDto { 
    TotalResults?: number;
    ParcelSearchResults?: Array<ParcelSearchResultWithMatchedFieldsDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ParcelSearchSummaryDtoForm { 
    TotalResults?: FormControl<number>;
    ParcelSearchResults?: FormControl<Array<ParcelSearchResultWithMatchedFieldsDto>>;
}

export class ParcelSearchSummaryDtoFormControls { 
    public static TotalResults = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelSearchResults = (value: FormControlState<Array<ParcelSearchResultWithMatchedFieldsDto>> | Array<ParcelSearchResultWithMatchedFieldsDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<ParcelSearchResultWithMatchedFieldsDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
