/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllocationPlanManageDto } from '../model/allocation-plan-manage-dto';
import { AllocationPlanMinimalDto } from '../model/allocation-plan-minimal-dto';
import { CustomRichTextDto } from '../model/custom-rich-text-dto';
import { FrequentlyAskedQuestionGridDto } from '../model/frequently-asked-question-grid-dto';
import { FrequentlyAskedQuestionLocationDisplayDto } from '../model/frequently-asked-question-location-display-dto';
import { GeographyBoundarySimpleDto } from '../model/geography-boundary-simple-dto';
import { GeographyPublicDto } from '../model/geography-public-dto';
import { GeographyWithBoundingBoxDto } from '../model/geography-with-bounding-box-dto';
import { MonitoringWellDataDto } from '../model/monitoring-well-data-dto';
import { MonitoringWellMeasurementDto } from '../model/monitoring-well-measurement-dto';
import { StateSimpleDto } from '../model/state-simple-dto';
import { SupportTicketQuestionTypeSimpleDto } from '../model/support-ticket-question-type-simple-dto';
import { SystemInfoDto } from '../model/system-info-dto';
import { ZoneGroupMinimalDto } from '../model/zone-group-minimal-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class PublicService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param customRichTextTypeID 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicCustomRichTextsCustomRichTextTypeIDGet(customRichTextTypeID: number, geographyID?: number, observe?: 'body', reportProgress?: boolean): Observable<CustomRichTextDto>;
    public publicCustomRichTextsCustomRichTextTypeIDGet(customRichTextTypeID: number, geographyID?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomRichTextDto>>;
    public publicCustomRichTextsCustomRichTextTypeIDGet(customRichTextTypeID: number, geographyID?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomRichTextDto>>;
    public publicCustomRichTextsCustomRichTextTypeIDGet(customRichTextTypeID: number, geographyID?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customRichTextTypeID === null || customRichTextTypeID === undefined) {
            throw new Error('Required parameter customRichTextTypeID was null or undefined when calling publicCustomRichTextsCustomRichTextTypeIDGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (geographyID !== undefined && geographyID !== null) {
            queryParameters = queryParameters.set('geographyID', <any>geographyID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CustomRichTextDto>(`${this.basePath}/public/customRichTexts/${encodeURIComponent(String(customRichTextTypeID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param frequentlyAskedQuestionID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicFaqFrequentlyAskedQuestionIDGet(frequentlyAskedQuestionID: number, observe?: 'body', reportProgress?: boolean): Observable<FrequentlyAskedQuestionGridDto>;
    public publicFaqFrequentlyAskedQuestionIDGet(frequentlyAskedQuestionID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FrequentlyAskedQuestionGridDto>>;
    public publicFaqFrequentlyAskedQuestionIDGet(frequentlyAskedQuestionID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FrequentlyAskedQuestionGridDto>>;
    public publicFaqFrequentlyAskedQuestionIDGet(frequentlyAskedQuestionID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (frequentlyAskedQuestionID === null || frequentlyAskedQuestionID === undefined) {
            throw new Error('Required parameter frequentlyAskedQuestionID was null or undefined when calling publicFaqFrequentlyAskedQuestionIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FrequentlyAskedQuestionGridDto>(`${this.basePath}/public/faq/${encodeURIComponent(String(frequentlyAskedQuestionID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicFaqGet(observe?: 'body', reportProgress?: boolean): Observable<Array<FrequentlyAskedQuestionGridDto>>;
    public publicFaqGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FrequentlyAskedQuestionGridDto>>>;
    public publicFaqGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FrequentlyAskedQuestionGridDto>>>;
    public publicFaqGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FrequentlyAskedQuestionGridDto>>(`${this.basePath}/public/faq`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param faqDisplayQuestionLocationTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicFaqLocationFaqDisplayQuestionLocationTypeIDGet(faqDisplayQuestionLocationTypeID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FrequentlyAskedQuestionLocationDisplayDto>>;
    public publicFaqLocationFaqDisplayQuestionLocationTypeIDGet(faqDisplayQuestionLocationTypeID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FrequentlyAskedQuestionLocationDisplayDto>>>;
    public publicFaqLocationFaqDisplayQuestionLocationTypeIDGet(faqDisplayQuestionLocationTypeID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FrequentlyAskedQuestionLocationDisplayDto>>>;
    public publicFaqLocationFaqDisplayQuestionLocationTypeIDGet(faqDisplayQuestionLocationTypeID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (faqDisplayQuestionLocationTypeID === null || faqDisplayQuestionLocationTypeID === undefined) {
            throw new Error('Required parameter faqDisplayQuestionLocationTypeID was null or undefined when calling publicFaqLocationFaqDisplayQuestionLocationTypeIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FrequentlyAskedQuestionLocationDisplayDto>>(`${this.basePath}/public/faq/location/${encodeURIComponent(String(faqDisplayQuestionLocationTypeID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesBoundingBoxGeographyNameGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyWithBoundingBoxDto>;
    public publicGeographiesBoundingBoxGeographyNameGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyWithBoundingBoxDto>>;
    public publicGeographiesBoundingBoxGeographyNameGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyWithBoundingBoxDto>>;
    public publicGeographiesBoundingBoxGeographyNameGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling publicGeographiesBoundingBoxGeographyNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyWithBoundingBoxDto>(`${this.basePath}/public/geographies/boundingBox/${encodeURIComponent(String(geographyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public publicGeographiesGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public publicGeographiesGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public publicGeographiesGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDAllocationPlanConfigurationDescriptionGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration/description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDAllocationPlansGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AllocationPlanMinimalDto>>;
    public publicGeographiesGeographyIDAllocationPlansGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AllocationPlanMinimalDto>>>;
    public publicGeographiesGeographyIDAllocationPlansGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AllocationPlanMinimalDto>>>;
    public publicGeographiesGeographyIDAllocationPlansGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDAllocationPlansGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AllocationPlanMinimalDto>>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/allocation-plans`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterTypeSlug 
     * @param zoneSlug 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'body', reportProgress?: boolean): Observable<AllocationPlanManageDto>;
    public publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllocationPlanManageDto>>;
    public publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllocationPlanManageDto>>;
    public publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet.');
        }

        if (waterTypeSlug === null || waterTypeSlug === undefined) {
            throw new Error('Required parameter waterTypeSlug was null or undefined when calling publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet.');
        }

        if (zoneSlug === null || zoneSlug === undefined) {
            throw new Error('Required parameter zoneSlug was null or undefined when calling publicGeographiesGeographyIDAllocationPlansWaterTypeSlugZoneSlugGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AllocationPlanManageDto>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/allocation-plans/${encodeURIComponent(String(waterTypeSlug))}/${encodeURIComponent(String(zoneSlug))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param siteCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDMonitoringWellSiteCodeGet(geographyID: number, siteCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MonitoringWellMeasurementDto>>;
    public publicGeographiesGeographyIDMonitoringWellSiteCodeGet(geographyID: number, siteCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MonitoringWellMeasurementDto>>>;
    public publicGeographiesGeographyIDMonitoringWellSiteCodeGet(geographyID: number, siteCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MonitoringWellMeasurementDto>>>;
    public publicGeographiesGeographyIDMonitoringWellSiteCodeGet(geographyID: number, siteCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDMonitoringWellSiteCodeGet.');
        }

        if (siteCode === null || siteCode === undefined) {
            throw new Error('Required parameter siteCode was null or undefined when calling publicGeographiesGeographyIDMonitoringWellSiteCodeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MonitoringWellMeasurementDto>>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/monitoring-well/${encodeURIComponent(String(siteCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDMonitoringWellsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MonitoringWellDataDto>>;
    public publicGeographiesGeographyIDMonitoringWellsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MonitoringWellDataDto>>>;
    public publicGeographiesGeographyIDMonitoringWellsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MonitoringWellDataDto>>>;
    public publicGeographiesGeographyIDMonitoringWellsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDMonitoringWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MonitoringWellDataDto>>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/monitoring-wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param zoneGroupSlug 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'body', reportProgress?: boolean): Observable<ZoneGroupMinimalDto>;
    public publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZoneGroupMinimalDto>>;
    public publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZoneGroupMinimalDto>>;
    public publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet(geographyID: number, zoneGroupSlug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet.');
        }

        if (zoneGroupSlug === null || zoneGroupSlug === undefined) {
            throw new Error('Required parameter zoneGroupSlug was null or undefined when calling publicGeographiesGeographyIDZoneGroupZoneGroupSlugGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ZoneGroupMinimalDto>(`${this.basePath}/public/geographies/${encodeURIComponent(String(geographyID))}/zone-group/${encodeURIComponent(String(zoneGroupSlug))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyPublicDto>>;
    public publicGeographiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyPublicDto>>>;
    public publicGeographiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyPublicDto>>>;
    public publicGeographiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyPublicDto>>(`${this.basePath}/public/geographies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesNameGeographyNameGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyPublicDto>;
    public publicGeographiesNameGeographyNameGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyPublicDto>>;
    public publicGeographiesNameGeographyNameGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyPublicDto>>;
    public publicGeographiesNameGeographyNameGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling publicGeographiesNameGeographyNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyPublicDto>(`${this.basePath}/public/geographies/name/${encodeURIComponent(String(geographyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyBoundariesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyBoundarySimpleDto>>;
    public publicGeographyBoundariesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyBoundarySimpleDto>>>;
    public publicGeographyBoundariesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyBoundarySimpleDto>>>;
    public publicGeographyBoundariesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyBoundarySimpleDto>>(`${this.basePath}/public/geographyBoundaries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicStatesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<StateSimpleDto>>;
    public publicStatesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StateSimpleDto>>>;
    public publicStatesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StateSimpleDto>>>;
    public publicStatesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StateSimpleDto>>(`${this.basePath}/public/states`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicSupportTicketQuestionTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketQuestionTypeSimpleDto>>;
    public publicSupportTicketQuestionTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketQuestionTypeSimpleDto>>>;
    public publicSupportTicketQuestionTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketQuestionTypeSimpleDto>>>;
    public publicSupportTicketQuestionTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketQuestionTypeSimpleDto>>(`${this.basePath}/public/support-ticket-question-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param geographyID 
     * @param description 
     * @param supportTicketStatusID 
     * @param supportTicketPriorityID 
     * @param supportTicketQuestionTypeID 
     * @param waterAccountWaterAccountID 
     * @param waterAccountGeographyID 
     * @param waterAccountWaterAccountNumber 
     * @param waterAccountWaterAccountName 
     * @param waterAccountNotes 
     * @param waterAccountUpdateDate 
     * @param waterAccountWaterAccountPIN 
     * @param waterAccountCreateDate 
     * @param waterAccountContactName 
     * @param waterAccountContactAddress 
     * @param assignedUserID 
     * @param contactFirstName 
     * @param contactLastName 
     * @param contactEmail 
     * @param contactPhoneNumber 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicSupportTicketsCreatePost(supportTicketID?: number, geographyID?: number, description?: string, supportTicketStatusID?: number, supportTicketPriorityID?: number, supportTicketQuestionTypeID?: number, waterAccountWaterAccountID?: number, waterAccountGeographyID?: number, waterAccountWaterAccountNumber?: number, waterAccountWaterAccountName?: string, waterAccountNotes?: string, waterAccountUpdateDate?: string, waterAccountWaterAccountPIN?: string, waterAccountCreateDate?: string, waterAccountContactName?: string, waterAccountContactAddress?: string, assignedUserID?: number, contactFirstName?: string, contactLastName?: string, contactEmail?: string, contactPhoneNumber?: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public publicSupportTicketsCreatePost(supportTicketID?: number, geographyID?: number, description?: string, supportTicketStatusID?: number, supportTicketPriorityID?: number, supportTicketQuestionTypeID?: number, waterAccountWaterAccountID?: number, waterAccountGeographyID?: number, waterAccountWaterAccountNumber?: number, waterAccountWaterAccountName?: string, waterAccountNotes?: string, waterAccountUpdateDate?: string, waterAccountWaterAccountPIN?: string, waterAccountCreateDate?: string, waterAccountContactName?: string, waterAccountContactAddress?: string, assignedUserID?: number, contactFirstName?: string, contactLastName?: string, contactEmail?: string, contactPhoneNumber?: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public publicSupportTicketsCreatePost(supportTicketID?: number, geographyID?: number, description?: string, supportTicketStatusID?: number, supportTicketPriorityID?: number, supportTicketQuestionTypeID?: number, waterAccountWaterAccountID?: number, waterAccountGeographyID?: number, waterAccountWaterAccountNumber?: number, waterAccountWaterAccountName?: string, waterAccountNotes?: string, waterAccountUpdateDate?: string, waterAccountWaterAccountPIN?: string, waterAccountCreateDate?: string, waterAccountContactName?: string, waterAccountContactAddress?: string, assignedUserID?: number, contactFirstName?: string, contactLastName?: string, contactEmail?: string, contactPhoneNumber?: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public publicSupportTicketsCreatePost(supportTicketID?: number, geographyID?: number, description?: string, supportTicketStatusID?: number, supportTicketPriorityID?: number, supportTicketQuestionTypeID?: number, waterAccountWaterAccountID?: number, waterAccountGeographyID?: number, waterAccountWaterAccountNumber?: number, waterAccountWaterAccountName?: string, waterAccountNotes?: string, waterAccountUpdateDate?: string, waterAccountWaterAccountPIN?: string, waterAccountCreateDate?: string, waterAccountContactName?: string, waterAccountContactAddress?: string, assignedUserID?: number, contactFirstName?: string, contactLastName?: string, contactEmail?: string, contactPhoneNumber?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {























        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (supportTicketID !== undefined) {
            formParams = formParams.append('SupportTicketID', <any>supportTicketID) || formParams;
        }
        if (geographyID !== undefined) {
            formParams = formParams.append('GeographyID', <any>geographyID) || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) || formParams;
        }
        if (supportTicketStatusID !== undefined) {
            formParams = formParams.append('SupportTicketStatusID', <any>supportTicketStatusID) || formParams;
        }
        if (supportTicketPriorityID !== undefined) {
            formParams = formParams.append('SupportTicketPriorityID', <any>supportTicketPriorityID) || formParams;
        }
        if (supportTicketQuestionTypeID !== undefined) {
            formParams = formParams.append('SupportTicketQuestionTypeID', <any>supportTicketQuestionTypeID) || formParams;
        }
        if (waterAccountWaterAccountID !== undefined) {
            formParams = formParams.append('WaterAccount.WaterAccountID', <any>waterAccountWaterAccountID) || formParams;
        }
        if (waterAccountGeographyID !== undefined) {
            formParams = formParams.append('WaterAccount.GeographyID', <any>waterAccountGeographyID) || formParams;
        }
        if (waterAccountWaterAccountNumber !== undefined) {
            formParams = formParams.append('WaterAccount.WaterAccountNumber', <any>waterAccountWaterAccountNumber) || formParams;
        }
        if (waterAccountWaterAccountName !== undefined) {
            formParams = formParams.append('WaterAccount.WaterAccountName', <any>waterAccountWaterAccountName) || formParams;
        }
        if (waterAccountNotes !== undefined) {
            formParams = formParams.append('WaterAccount.Notes', <any>waterAccountNotes) || formParams;
        }
        if (waterAccountUpdateDate !== undefined) {
            formParams = formParams.append('WaterAccount.UpdateDate', <any>waterAccountUpdateDate) || formParams;
        }
        if (waterAccountWaterAccountPIN !== undefined) {
            formParams = formParams.append('WaterAccount.WaterAccountPIN', <any>waterAccountWaterAccountPIN) || formParams;
        }
        if (waterAccountCreateDate !== undefined) {
            formParams = formParams.append('WaterAccount.CreateDate', <any>waterAccountCreateDate) || formParams;
        }
        if (waterAccountContactName !== undefined) {
            formParams = formParams.append('WaterAccount.ContactName', <any>waterAccountContactName) || formParams;
        }
        if (waterAccountContactAddress !== undefined) {
            formParams = formParams.append('WaterAccount.ContactAddress', <any>waterAccountContactAddress) || formParams;
        }
        if (assignedUserID !== undefined) {
            formParams = formParams.append('AssignedUserID', <any>assignedUserID) || formParams;
        }
        if (contactFirstName !== undefined) {
            formParams = formParams.append('ContactFirstName', <any>contactFirstName) || formParams;
        }
        if (contactLastName !== undefined) {
            formParams = formParams.append('ContactLastName', <any>contactLastName) || formParams;
        }
        if (contactEmail !== undefined) {
            formParams = formParams.append('ContactEmail', <any>contactEmail) || formParams;
        }
        if (contactPhoneNumber !== undefined) {
            formParams = formParams.append('ContactPhoneNumber', <any>contactPhoneNumber) || formParams;
        }
        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/public/support-tickets/create`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicSystemInfoGet(observe?: 'body', reportProgress?: boolean): Observable<SystemInfoDto>;
    public publicSystemInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SystemInfoDto>>;
    public publicSystemInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SystemInfoDto>>;
    public publicSystemInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SystemInfoDto>(`${this.basePath}/public/system-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
