<div class="full-width-page">
    <page-header page-title="Support Tickets" [templateRight]="templateRight"></page-header>
    <ng-template #templateRight>
        <button class="btn btn-primary" (click)="openCreateNewModal()">+ Add Support Ticket</button>
    </ng-template>
    <app-alert-display></app-alert-display>
    <!-- <custom-rich-text [customRichTextTypeID]="customRichTextID"></custom-rich-text> -->
    <div>
        <qanat-grid
            *ngIf="supportTicketDtos$ | async as supportTicketDtos"
            [rowData]="supportTicketDtos"
            [columnDefs]="columnDefs"
            downloadFileName="support-tickets"
            rowSelection="multiple"
            [suppressRowClickSelection]="true"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onGridReady($event)">
        </qanat-grid>
    </div>
</div>
