<div class="form-group">
    <div class="field">
        <div class="search-container">
            <div class="search-prepend">
                <i class="fa" [class.fa-search]="!isSearching" [class.fa-spinner]="isSearching" [class.spin-animation]="isSearching"></i>
            </div>
            <input
                onkeydown="return event.key != 'Enter';"
                (keyup.arrowdown)="selectNext()"
                (keyup.arrowup)="selectPrevious()"
                (keyup.enter)="selectCurrent($event)"
                type="text"
                name="ParcelSearch"
                placeholder="APN, Owner Name, Owner Address"
                [formControl]="searchString"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false" />
            <div class="search-append">
                <button class="clear" (click)="clearSearch()" *ngIf="searchString.value">
                    <i class="fa fa-times-circle"></i>
                </button>
                <button class="clear" (click)="toggleDropdown()" *ngIf="isPartOfForm">
                    <icon icon="AngleDown"></icon>
                </button>
            </div>
        </div>
        <div class="search-results" *ngIf="searchResults$ | async as searchResults">
            <div class="refine-search p-3" *ngIf="searchResults?.TotalResults > 10 && !isSearching && !val">Displaying 10 of {{ searchResults?.TotalResults }} results.</div>
            <ul *ngIf="val == null && !isSearching">
                <li
                    *ngFor="let result of searchResults.ParcelSearchResults"
                    [id]="'Parcel_' + result.Parcel.ParcelID"
                    [class.highlighted-search-result]="result == highlightedSearchResult">
                    <button (click)="selectParcel(result)">
                        <div class="button-head">
                            <icon [icon]="'Parcels'"></icon>
                            <strong class="wa-name" [innerHTML]="'#' + result.Parcel.ParcelNumber" [highlight]="searchString.value"></strong>
                        </div>

                        <div class="button-metadata mt-1" *ngIf="result.Parcel.WaterAccountNumber">
                            <small class="wa-apns wa-meta">
                                <strong>Water Account Number:</strong>
                                <span [highlight]="searchString.value" [innerHTML]="result.Parcel.WaterAccountNumber"></span>
                            </small>
                        </div>

                        <ng-container *ngIf="searchString.value && searchString.value.length > 0">
                            <div class="button-metadata mt-1" *ngIf="result.MatchedFields.WaterAccountName">
                                <small class="wa-apns wa-meta">
                                    <strong>Water Account Name:</strong>
                                    <span [highlight]="searchString.value" [innerHTML]="result.Parcel.WaterAccountName"></span>
                                </small>
                            </div>

                            <div class="button-metadata mt-1" *ngIf="result.MatchedFields.ContactName">
                                <small class="wa-contact-name wa-meta">
                                    <strong>Contact Name:</strong>
                                    <span [highlight]="searchString.value" [innerHTML]="result.Parcel.ContactName"></span>
                                </small>
                            </div>

                            <div class="button-metadata mt-1" *ngIf="result.MatchedFields.ContactAddress">
                                <small class="wa-contact-address wa-meta">
                                    <strong>Contact Address:</strong>
                                    <span [highlight]="searchString.value" [innerHTML]="result.Parcel.ContactAddress"></span>
                                </small>
                            </div>
                        </ng-container>
                    </button>
                </li>

                <li *ngIf="searchResults?.ParcelSearchResults?.length == 0 && searchString.value && searchString.value.length > 1" class="p-3">No results</li>
            </ul>
        </div>
    </div>
</div>
