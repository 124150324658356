<div class="modal-header">
    <h2>
        <icon icon="Parcels"></icon>
        Update Ownership Info
    </h2>
    <button type="button" class="close" (click)="close()">
        <i class="fa fa-times-circle"></i>
    </button>
</div>

<div class="modal-body">
    <form action="" [formGroup]="formGroup" class="grid-12" *ngIf="parcel$ | async as parcel">
        <form-field [formControl]="formGroup.controls.OwnerName" [required]="true" fieldLabel="Owner Name" [type]="FormFieldType.Text"></form-field>
        <form-field [formControl]="formGroup.controls.OwnerAddress" [required]="true" fieldLabel="Owner Address" [type]="FormFieldType.Text"></form-field>
        <note>
            Are you sure you want to update ownership info for parcel
            <strong>{{ parcel.ParcelNumber }}</strong>
            ?
        </note>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()" [class.disabled]="!formGroup.valid">Update Ownership Info</button>
    <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
</div>
