<div>
    <page-header icon="Users" pageTitle="Water Managers" [customRichTextTypeID]="customRichTextTypeID"></page-header>
    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>
        <div>
            <form (ngSubmit)="onSubmit(editGeographyWaterManagersForm)" #editGeographyWaterManagersForm="ngForm" class="form grid-12">
                <div class="field">
                    <div class="directions pb-2 copy copy-2">
                        <p>Select user(s) to associate with this account:</p>
                    </div>
                    <div class="grid-12">
                        <ngx-select-dropdown
                            class="g-col-10"
                            [multiple]="false"
                            [config]="userDropdownConfig"
                            name="selectedUser"
                            [(ngModel)]="selectedUser"
                            [options]="filteredUsers"></ngx-select-dropdown>
                        <button class="btn btn-secondary btn-sm g-col-2" type="button" (click)="addUser()" [disabled]="!selectedUser">Add</button>
                    </div>
                    <p class="copy">
                        These users will be associated with the account. Click the
                        <span class="fas fa-times-circle text-muted"></span>
                        icon next to a user to remove it
                    </p>
                </div>

                <div class="field">
                    <ul class="usersList">
                        <li *ngFor="let user of usersToSave" class="grid-12">
                            <div class="g-col-6 user">
                                {{ user?.FullName || (user?.FirstName || user?.LastName ? user?.FirstName + " " + user?.LastName : null) }}
                                <button class="btn btn-underline" (click)="removeUser(user)">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row mt-4">
                    <div class="col-sm-6 text-right">
                        <button type="submit" class="btn btn-secondary" [disabled]="!editGeographyWaterManagersForm.form.valid || isLoadingSubmit">
                            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                            Save
                        </button>
                        <a class="btn btn-secondary-outline ml-1" routerLink="..">Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
