<page-header
    *ngIf="currentWaterAccount"
    [pageTitle]="'#' + currentWaterAccount.WaterAccountNumber"
    [templateTitleAppend]="templateTitleAppend"
    icon="WaterAccounts"
    preTitle="Water Budget">
    <ng-template #templateTitleAppend>
        <span
            class="water-account-name"
            *ngIf="currentWaterAccount && currentWaterAccount.WaterAccountName != currentWaterAccount.WaterAccountNumber.toString()"
            title="{{ currentWaterAccount.WaterAccountName }}">
            {{ currentWaterAccount.WaterAccountName }}
        </span>
        <name-tag
            *ngIf="allocationPlans?.length > 0"
            [name]="allocationPlans[0].ZoneName"
            [color]="allocationPlans[0].ZoneColor"
            [routerLink]="[
                '/geographies',
                currentWaterAccount.Geography.GeographyName.toLowerCase(),
                'allocation-plans',
                allocationPlans[0].WaterTypeSlug,
                allocationPlans[0].ZoneSlug
            ]"
            class="zone-tag"
            title="Allocation Plan Zone"></name-tag>
    </ng-template>
</page-header>

<div class="page-body" [loadingSpinner]="{ isLoading: isLoading, loadingHeight: 500 }">
    <app-alert-display></app-alert-display>
    <div class="card custom-attributes g-col-10" *ngIf="waterAccountCustomAttributes$ | async as waterAccountCustomAttributes">
        <div class="card-header flex-between">
            <h3 class="card-title">
                Attributes
                <name-tag name="Admin" color="#000"></name-tag>
            </h3>
            <a *ngIf="waterAccountCustomAttributes.CustomAttributes" class="btn btn-sm btn-secondary" routerLink="edit-attributes">
                <span class="fas fa-edit"></span>
                Edit Attributes
            </a>
        </div>
        <div class="card-body">
            <key-value-pair-list *ngIf="waterAccountCustomAttributes.CustomAttributes; else noCustomAttributesConfigured">
                <key-value-pair
                    [horizontal]="true"
                    [copyValueToClipboard]="customAttribute.value !== ''"
                    *ngFor="let customAttribute of waterAccountCustomAttributes.CustomAttributes | keyvalue">
                    <ng-container key>{{ customAttribute.key }}</ng-container>
                    <ng-container keyValue>
                        {{ customAttribute.value }}
                        <em *ngIf="!customAttribute.value" class="text-muted">Not Available</em>
                    </ng-container>
                </key-value-pair>
            </key-value-pair-list>
            <ng-template #noCustomAttributesConfigured>
                <div class="copy copy-3 no-custom-attributes">
                    No custom attributes have been configured for Water Accounts in this geography.
                    <br />
                    Contact an administrator to learn more about this feature.
                </div>
            </ng-template>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h3 class="water-account-title | section-title">
                Management Actions
                <name-tag name="Admin" color="#000"></name-tag>
            </h3>
        </div>
        <div class="card-body management-actions">
            <div class="flex-start">
                <a href="javascript:void(0);" (click)="openUpdateParcelsModal()">
                    <icon icon="Parcels"></icon>
                    Update Parcels
                </a>
                <a href="javascript:void(0);" (click)="openUpdateInfoModal()">
                    <icon icon="Info"></icon>
                    Update Info
                </a>
                <a href="javascript:void(0);" (click)="openMergeModal()">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.98137 13.467L0.146973 12.6686C1.23977 10.159 2.66857 8.27182 4.36697 7.15502C2.66857 6.03822 1.23897 4.15102 0.146973 1.64142L1.98137 0.843018C3.03577 3.26702 4.86537 5.99502 8.14697 5.99502L10.547 5.99502V3.55502L16.147 7.15502L10.547 10.755V8.31502H8.14697C4.86537 8.31502 3.03577 11.043 1.98137 13.467Z"
                            fill="#0D5B73" />
                    </svg>
                    Merge
                </a>
                <a href="javascript:void(0);" (click)="openDeleteModal()">
                    <i class="fa fa-times-circle text-danger"></i>
                    Delete
                </a>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="currentWaterAccount">
        <div class="card-header">
            <h3 class="water-account-title | section-title">
                Water Account Pin
                <name-tag name="Admin" color="#000"></name-tag>
            </h3>
        </div>
        <div class="card-body">
            <dl class="kv-pair">
                <dt class="key">
                    <field-definition fieldDefinitionType="WaterAccountPIN" [inline]="false"></field-definition>
                </dt>
                <dd class="value">
                    {{ currentWaterAccount.WaterAccountPIN }}
                    <em *ngIf="!currentWaterAccount.WaterAccountPIN" class="text-muted"> Not Available </em>
                </dd>

                <dt class="key">Water Account PIN Last Used</dt>
                <dd class="value">
                    {{ currentWaterAccount.WaterAccountPINLastUsed | date : "short" }}
                    <em *ngIf="!currentWaterAccount.WaterAccountPINLastUsed" class="text-muted"> Not Available </em>
                </dd>
            </dl>
        </div>
    </div>
</div>
