<page-header pageTitle="Parcel Attributes" [customRichTextTypeID]="customRichTextTypeID"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <entity-custom-attributes-edit
        *ngIf="parcelCustomAttributes$ | async as parcelCustomAttributes"
        [entityCustomAttributes]="parcelCustomAttributes"
        [isLoadingSubmit]="isLoadingSubmit"
        (save)="save($event)"></entity-custom-attributes-edit>
</div>
