<div class="dashboard">
    <ng-container *ngIf="waterAccounts$ | async as waterAccounts; else isLoadingTemplate">
        <aside class="sidebar">
            <div class="sidebar-header">
                <a routerLink="/water-dashboard/water-accounts">
                    <geography-logo
                        [geographyID]="currentWaterAccount.Geography.GeographyID"
                        *ngIf="this.currentWaterAccount && this.currentWaterAccount.Geography && currentWaterAccount.Geography.GeographyID"></geography-logo>
                </a>
            </div>
            <div class="sidebar-body sticky-nav">
                <div class="sidebar-body__wrapper">
                    <h3 class="sidebar-body__title">
                        <icon icon="WaterAccounts"></icon>
                        Water Account
                    </h3>
                    <div class="sidebar-body__switcher">
                        <ng-container *ngIf="viewingWaterAccount; else atRootSidebar">
                            <div class="sidebar-body__switcher" *ngIf="waterAccounts?.length > 1">
                                <span class="dropdown sidebar-body__current">
                                    <a
                                        href="javascript:void(0);"
                                        [dropdownToggle]="waterAccountToggle"
                                        [title]="currentWaterAccount.WaterAccountNameAndNumber"
                                        class="dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        (click)="waterAccountSwitcher.toggleDropdown()">
                                        <span class="label">{{ currentWaterAccount.WaterAccountNameAndNumber }}</span>

                                        <icon icon="AngleDown"></icon>
                                    </a>

                                    <div #waterAccountToggle class="dropdown-menu">
                                        <search-water-accounts
                                            #waterAccountSwitcher
                                            [isPartOfForm]="false"
                                            [excludedWaterAccountIDs]="[currentWaterAccount.WaterAccountID]"
                                            (change)="changedWaterAccount($event)"></search-water-accounts>
                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <dashboard-menu [dashboardMenu]="dashboardMenu" [viewingDetailPage]="true"></dashboard-menu>
            </div>
        </aside>

        <main class="main">
            <router-outlet #manageOutlet="outlet"></router-outlet>
        </main>
    </ng-container>

    <ng-template #isLoadingTemplate>
        <aside class="sidebar" [loadingSpinner]="{ isLoading: true }"></aside>
        <main class="main" [loadingSpinner]="{ isLoading: true }"></main>
    </ng-template>
</div>
