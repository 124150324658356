/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SupportTicketGridDto } from '../model/support-ticket-grid-dto';
import { SupportTicketNoteFeedDto } from '../model/support-ticket-note-feed-dto';
import { SupportTicketNoteSimpleDto } from '../model/support-ticket-note-simple-dto';
import { SupportTicketPrioritySimpleDto } from '../model/support-ticket-priority-simple-dto';
import { SupportTicketStatusSimpleDto } from '../model/support-ticket-status-simple-dto';
import { SupportTicketUpsertDto } from '../model/support-ticket-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class SupportTicketService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param supportTicketUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsCreatePost(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supportTicketsCreatePost(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supportTicketsCreatePost(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supportTicketsCreatePost(supportTicketUpsertDto?: SupportTicketUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/support-tickets/create`,
            supportTicketUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsEditPut(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supportTicketsEditPut(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supportTicketsEditPut(supportTicketUpsertDto?: SupportTicketUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supportTicketsEditPut(supportTicketUpsertDto?: SupportTicketUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/support-tickets/edit`,
            supportTicketUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketGridDto>>;
    public supportTicketsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketGridDto>>>;
    public supportTicketsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketGridDto>>>;
    public supportTicketsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketGridDto>>(`${this.basePath}/support-tickets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsPrioritiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketPrioritySimpleDto>>;
    public supportTicketsPrioritiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketPrioritySimpleDto>>>;
    public supportTicketsPrioritiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketPrioritySimpleDto>>>;
    public supportTicketsPrioritiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketPrioritySimpleDto>>(`${this.basePath}/support-tickets/priorities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketIDClosePut(supportTicketID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supportTicketsSupportTicketIDClosePut(supportTicketID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supportTicketsSupportTicketIDClosePut(supportTicketID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supportTicketsSupportTicketIDClosePut(supportTicketID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supportTicketID === null || supportTicketID === undefined) {
            throw new Error('Required parameter supportTicketID was null or undefined when calling supportTicketsSupportTicketIDClosePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/support-tickets/${encodeURIComponent(String(supportTicketID))}/close`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketIDGet(supportTicketID: number, observe?: 'body', reportProgress?: boolean): Observable<SupportTicketGridDto>;
    public supportTicketsSupportTicketIDGet(supportTicketID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SupportTicketGridDto>>;
    public supportTicketsSupportTicketIDGet(supportTicketID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SupportTicketGridDto>>;
    public supportTicketsSupportTicketIDGet(supportTicketID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supportTicketID === null || supportTicketID === undefined) {
            throw new Error('Required parameter supportTicketID was null or undefined when calling supportTicketsSupportTicketIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SupportTicketGridDto>(`${this.basePath}/support-tickets/${encodeURIComponent(String(supportTicketID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketIDNotesGet(supportTicketID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketNoteFeedDto>>;
    public supportTicketsSupportTicketIDNotesGet(supportTicketID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketNoteFeedDto>>>;
    public supportTicketsSupportTicketIDNotesGet(supportTicketID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketNoteFeedDto>>>;
    public supportTicketsSupportTicketIDNotesGet(supportTicketID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supportTicketID === null || supportTicketID === undefined) {
            throw new Error('Required parameter supportTicketID was null or undefined when calling supportTicketsSupportTicketIDNotesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketNoteFeedDto>>(`${this.basePath}/support-tickets/${encodeURIComponent(String(supportTicketID))}/notes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param supportTicketNoteSimpleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketIDNotesPost(supportTicketID: number, supportTicketNoteSimpleDto?: SupportTicketNoteSimpleDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supportTicketsSupportTicketIDNotesPost(supportTicketID: number, supportTicketNoteSimpleDto?: SupportTicketNoteSimpleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supportTicketsSupportTicketIDNotesPost(supportTicketID: number, supportTicketNoteSimpleDto?: SupportTicketNoteSimpleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supportTicketsSupportTicketIDNotesPost(supportTicketID: number, supportTicketNoteSimpleDto?: SupportTicketNoteSimpleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supportTicketID === null || supportTicketID === undefined) {
            throw new Error('Required parameter supportTicketID was null or undefined when calling supportTicketsSupportTicketIDNotesPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/support-tickets/${encodeURIComponent(String(supportTicketID))}/notes`,
            supportTicketNoteSimpleDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param supportTicketID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketIDReopenPut(supportTicketID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supportTicketsSupportTicketIDReopenPut(supportTicketID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supportTicketsSupportTicketIDReopenPut(supportTicketID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supportTicketsSupportTicketIDReopenPut(supportTicketID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supportTicketID === null || supportTicketID === undefined) {
            throw new Error('Required parameter supportTicketID was null or undefined when calling supportTicketsSupportTicketIDReopenPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/support-tickets/${encodeURIComponent(String(supportTicketID))}/reopen`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supportTicketsSupportTicketStatusesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketStatusSimpleDto>>;
    public supportTicketsSupportTicketStatusesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketStatusSimpleDto>>>;
    public supportTicketsSupportTicketStatusesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketStatusSimpleDto>>>;
    public supportTicketsSupportTicketStatusesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketStatusSimpleDto>>(`${this.basePath}/support-tickets/support-ticket-statuses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
