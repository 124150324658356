<page-header pageTitle="Custom Attributes"></page-header>

<div class="page-body" *ngIf="currentUser$ | async">
    <div class="form page-controls" [formGroup]="formGroup">
        <form-field
            *ngIf="customAttributeTypes$ | async"
            [formInputOptions]="customAttributeTypeDropdownOptions"
            [formControl]="formGroup.controls.CustomAttributeTypeID"
            fieldLabel="Custom Attribute Type"
            [type]="FormFieldType.Select">
        </form-field>
    </div>

    <app-alert-display></app-alert-display>

    <div class="card">
        <div class="card-header">
            <h3 class="card-title">{{ isReadonly ? "View" : "Update" }} Custom Attributes</h3>
        </div>

        <div class="card-body">
            <custom-attributes-edit
                *ngIf="customAttributeData$ | async"
                [loadingSpinner]="{ isLoading: isLoading }"
                [customAttributes]="customAttributes"
                [geographyID]="formGroup.controls.GeographyID.value"
                [customAttributeTypeID]="formGroup.controls.CustomAttributeTypeID.value"
                [readonly]="isReadonly">
            </custom-attributes-edit>
        </div>

        <div class="card-footer flex-end" *ngIf="!isReadonly">
            <button type="submit" class="btn btn-primary" [disabled]="isLoadingSubmit" [buttonLoading]="isLoadingSubmit" (click)="save()">Save</button>
        </div>
    </div>
</div>
