<page-header
    *ngIf="geography"
    icon="Allocations"
    [templateTitleAppend]="templateTitleAppend"
    [customRichTextTypeID]="customRichTextTypeID"
    [customRichTextTypeGeographyID]="geography.GeographyID"
    pageTitle="Allocation Plans">
    <ng-template #templateTitleAppend>
        <name-tag [name]="geography.GeographyName" class="mb-2"></name-tag>
    </ng-template>
</page-header>

<div class="page-body grid-12" *ngIf="allocationPlans$ | async as allocationPlans">
    <app-alert-display></app-alert-display>

    <ng-container *ngIf="zoneGroup$ | async as zoneGroup">
        <allocation-plan-select [geographyID]="geography.GeographyID" [allocationPlans]="allocationPlans" [zoneGroupName]="zoneGroup.ZoneGroupName"></allocation-plan-select>

        <h2 class="section-title mt-2">{{ zoneGroup.ZoneGroupName }} Reference Map</h2>

        <div class="grid-12">
            <div class="g-col-3">
                <zone-group-map-legend [zoneGroup]="zoneGroup"></zone-group-map-legend>
            </div>

            <div class="g-col-9">
                <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="400px" [boundingBox]="geography.BoundingBox">
                    <zone-group-layer
                        *ngIf="mapIsReady"
                        [displayOnLoad]="true"
                        [zoneGroupID]="zoneGroup.ZoneGroupID"
                        [zoneGroupName]="zoneGroup.ZoneGroupName"
                        [map]="map"
                        [layerControl]="layerControl"></zone-group-layer>
                </qanat-map>
            </div>
        </div>
    </ng-container>
</div>
